import React from "react";
import styled, { css } from "styled-components";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const php = new Php();

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  align-self: center;
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;

export default function AddMaster(props) {
  const [loading, setLoading] = React.useState(false);
  const { addNotification } = useNotification();

  const [error, setError] = React.useState("");
  const [name, setName] = React.useState("");
  const [username, setUserame] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [margin, setMargin] = React.useState(0);
  const [alias, setAlias] = React.useState("");

  const [superMargin, setSuperMargin] = React.useState([]);
  // const superMargin = JSON.parse(localStorage.getItem("supersDetails")).margin;

  const superMarginLocal = JSON.parse(
    localStorage.getItem("supersDetails")
  ).margin;

  React.useEffect(() => {
    load_super_brokerages();
  }, []);

  const load_super_brokerages = () => {
    if (!loading) {
      setLoading(true);
      let data = {
        sr: localStorage.getItem("server"),
        jwt: localStorage.getItem("token"),
        s_id: localStorage.getItem("supersId"),
      };

      php.load_super_brokerages(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setSuperMargin(r.supers_margin);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const add_master = (e) => {
    setError("");
    if (name == "") {
      addNotification("Please Enter Name", "error");
    } else if (username == "" || username.length > 14 || username.length < 2) {
      addNotification("Please Enter Username Upto 14 Character", "error");
    } else if (password == "" || password.length < 3) {
      addNotification(
        "Please Enter Password with atleast 3 character",
        "error"
      );
    } else if (margin < 0 || margin > parseFloat(superMargin)) {
      addNotification(
        "Please Enter Margin Between 0 to " + superMargin,
        "error"
      );
    } else if (alias?.length > 6 && alias != "") {
      addNotification("Please Enter Alais Between 0 to 6 Character", "error");
    } else {
      if (!loading) {
        setLoading(false);
        let data = {
          sr: localStorage.getItem("server"),
          jwt: localStorage.getItem("token"),
          s_id: localStorage.getItem("supersId"),
          name: name,
          alias: alias,
          username: username,
          password: password,
          margin: margin,
        };

        php.add_master(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            addNotification(r.message, "success");
            props.reload();
          } else {
            addNotification(r.message, "error");
          }
        });
      }
    }
  };

  const calcSharing = (value) => {
    let margin =
      superMargin >= 0 ? parseFloat(superMargin) : parseFloat(superMarginLocal);

    if (value > margin || margin < 0) {
      setMargin("");
      addNotification(
        "Sharing Must Be Between 0 To " + parseFloat(superMargin).toFixed(0),
        "error"
      );
      // } else if (sharing < 1 && value > 0) {
      //   setSharing(0);
      //   alert.error("Sharing Must Be Between 0 To " + sharing);
    } else {
      setMargin(parseFloat(value).toFixed(0));
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        height: "100%",
        // padding: 10,
        boxSizing: "border-box",
      }}
    >
      {/* <Patti /> */}
      <Card
        title="Add Master"
        style={{
          margin: 10,
          position: "relative",
          height: "calc(100% - (60px - 1px))",
          overflowY: "scroll",
        }}
      >
        <CreateClientButton
          active={false}
          color={NewTheme.redcolor}
          onClick={() => props.close()}
          style={{ position: "absolute", top: 10, right: 5 }}
        >
          Close
        </CreateClientButton>
        <RightPanelContainer>
          <BalanceWrapper>
            <BalanceContainer>
              <BalanceLabel> Name </BalanceLabel>
              <BalanceInput
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Name "
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Username </BalanceLabel>
              <BalanceInput
                type="text"
                placeholder="Enter Username 2 to 14 Character "
                onChange={(e) => setUserame(e.target.value)}
                value={username}
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Password</BalanceLabel>
              <BalanceInput
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password with atleast 3 character"
              />
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Margin</BalanceLabel>

              <div
                className="p-inputgroup flex-1"
                style={{ height: 40, width: "50%" }}
              >
                <InputText
                  type="number"
                  value={margin}
                  onChange={(e) => calcSharing(e.target.value)}
                  placeholder="Enter "
                />

                <span
                  className="p-inputgroup-addon"
                  style={{
                    backgroundColor: NewTheme.MainColor,
                    color: "#ffffff",
                  }}
                >
                  {"Your Margin : " +
                    (parseFloat(superMargin).toFixed(0) - margin) +
                    "%"}
                </span>
              </div>
            </BalanceContainer>
            <BalanceContainer>
              <BalanceLabel>Alias</BalanceLabel>
              <BalanceInput
                type="text"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                placeholder="Enter Master Alias "
              />
            </BalanceContainer>
          </BalanceWrapper>
          <BalanceUpdateButton onClick={add_master}>Save</BalanceUpdateButton>
        </RightPanelContainer>
      </Card>
    </div>
  );
}
